@import './variable.less';
@import './fonts.less';

.h1 {
    font-size: 30px;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: 600;

    &_center {
        text-align: center;
    }
}

.h2 {
    font-size: 30px;
    line-height: 1.5;
    text-transform: uppercase;
    font-weight: 600;

    &_center {
        text-align: center;
    }

    &_orange {
        color: @orange;
    }

    &_32 {
        font-size: 32px;
    }
}

.bold {
    font-weight: 600;
}

.uppercase {
    text-transform: uppercase;
}

.orange-txt {
    color: @orange;
}

.p {
    font-size: 1rem;
    line-height: 1.3;
}

@media (max-width: 570px) {

    .h1 {
        font-size: 18px;
    }

    .h2 {
        font-size: 18px;
    }
}