@import './variable.less';

.banner-section {
    height: 220px;
    width: 100%;
    background-image: url(../img/stro.jpg);
    background-repeat: repeat;
    background-position: bottom;
    background-size: 1220px;
}

@media (max-width: 570px) {
    .banner-section {
        height: 110px;
        background-size: 407px;
    }
}