@import './variable.less';
@import './fonts.less';

.call-back-form-wrap {
    padding-top: 40px;
    padding-bottom: 40px;
}

.input,
.textarea {
    border: 1px solid @borderGray;
    padding: 23px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    resize: none;
}

.textarea_h-130 {
    height: 130px;
}

.form_col-3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-item {
        width: 32.5%;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.form-item.form-item_full {
    width: 100%;
}

.form-item {
    position: relative;
}

.form-alert {
    position: absolute;
    left: 0;
    top: -20px;
    font-size: 14px;
    padding: 5px;
    border: 1px solid @error;
    background-color: @white;

    &::after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-left: 1px solid @error;
        border-bottom: 1px solid @error;
        background-color: @white;
        transform: rotate(-45deg);
        position: absolute;
        top: 100%;
        left: 15px;
        margin-top: -2px;    
        z-index: 1;
    }
}

@media (max-width: 570px) {
    .call-back-form-wrap {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .form_col-3 {
        .form-item {
            width: 100%;
        }
    }

    .input, 
    .textarea {
        padding: 10px;
    }
}
