@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.general-advantages-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 329px;
}
.general-advantages-item .img-wrap {
  width: 105px;
  height: 105px;
  background-color: #e97402;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 20px;
}
.general-advantages-item .img {
  width: 78px;
  height: 60px;
  max-width: 90%;
  max-height: 90%;
}
.general-advantages-item .advantage-title {
  font-size: 20px;
  line-height: 1.3;
}
@media (max-width: 1000px) {
  .general-advantages-item {
    width: 33%;
  }
  .general-advantages-item .advantage-title {
    font-size: 18px;
  }
}
@media (max-width: 780px) {
  .general-advantages-item .advantage-title {
    font-size: 15px;
  }
}
@media (max-width: 570px) {
  .general-advantages-item {
    width: 100%;
    flex-direction: row;
    margin-bottom: 20px;
  }
  .general-advantages-item .img-wrap {
    width: 75px;
    height: 75px;
    margin-bottom: 0;
  }
  .general-advantages-item .img {
    width: 60%;
    height: auto;
  }
  .general-advantages-item .advantage-title {
    text-align: left;
    padding-left: 15px;
    width: 75%;
  }
}

.fade-in-extention {
  animation-name: fadeInExtention;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
.fade-out-extention {
  animation-name: fadeOutExtention;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
@keyframes fadeInExtention {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeOutExtention {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}

.banner-section {
  height: 220px;
  width: 100%;
  background-image: url(../img/stro.jpg);
  background-repeat: repeat;
  background-position: bottom;
  background-size: 1220px;
}
@media (max-width: 570px) {
  .banner-section {
    height: 110px;
    background-size: 407px;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.btn {
  text-transform: uppercase;
  border: 1px solid white;
  color: white;
  font-weight: 600;
  background-color: #005f4c;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.2;
  cursor: pointer;
  transition: 0.2s;
}
.btn:hover {
  background-color: #003f33;
}
.btn_large {
  font-size: 24px;
  padding: 20px 80px;
}
@media (max-width: 1000px) {
  .btn_large {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@media (max-width: 570px) {
  .btn_large {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.catalog-list_m-t {
  margin-top: 40px;
}
.catalog-list-item {
  width: 20%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border: 1px solid #dddddd;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
}
.catalog-list-item:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;
  background-color: white;
}
.catalog-list-item:hover::before {
  z-index: 5;
  background-color: white;
  transform: rotate(0deg);
  width: 100%;
  height: 15px;
  margin-top: -19px;
  border: none;
}
.catalog-list-item:hover .menu-list {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  opacity: 1;
  visibility: visible;
}
.catalog-list-item:hover .img {
  display: none;
}
.catalog-list-item:hover .img_hover {
  display: block;
}
.catalog-list-item::after,
.catalog-list-item::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: -7px;
  background-color: white;
  width: 15px;
  height: 15px;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  transform: rotate(-45deg);
}
.catalog-list-item::before {
  width: 8px;
  height: 8px;
  margin-top: -8px;
  z-index: 1;
}
.catalog-list-item:nth-child(1),
.catalog-list-item:nth-child(2),
.catalog-list-item:nth-child(3),
.catalog-list-item:nth-child(4),
.catalog-list-item:nth-child(5) {
  border-top: 1px solid #dddddd;
}
.catalog-list-item:nth-child(5n+5),
.catalog-list-item:last-child {
  border-right: 1px solid #dddddd;
}
.catalog-list-item_no-sub::after,
.catalog-list-item_no-sub::before {
  display: none;
}
.catalog-list-item .img {
  max-height: 67px;
  max-width: 65px;
}
.catalog-list-item .img_hover {
  display: none;
}
.catalog-list-item .menu-list {
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  left: 0;
  width: 100%;
  position: absolute;
  background-color: white;
  z-index: 2;
  margin-top: -5px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  border-bottom: 1px solid #dddddd;
}
.catalog-list-item .menu-list-item {
  line-height: 1.3;
  padding-left: 40px;
  position: relative;
  color: #292929;
  text-decoration: none;
  padding-bottom: 10px;
  transition: 0.2s;
  cursor: default;
}
.catalog-list-item .menu-list-item:last-child {
  padding-bottom: 0;
}
.catalog-list-item .menu-list-item::before {
  transition: 0.2s;
}
.catalog-list-item .menu-list-item_close {
  display: none;
}
.catalog-list-item .title-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #292929;
  pointer-events: none;
}
.catalog-list-item .title-block-title {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 13px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.3;
  transition: 0.2s;
}
.catalog-list-item .title-block-title:hover {
  color: #005f4c;
}
.additional-features-list {
  display: flex;
  flex-wrap: wrap;
}
.additional-features-list .list-item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additional-features-list .list-item .img {
  max-width: 100px;
  max-height: 93px;
  margin-bottom: 20px;
}
.additional-features-list .list-item-title {
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
}
@media (max-width: 1000px) {
  .catalog-list-item {
    width: 25%;
  }
  .catalog-list-item:nth-child(5n+5) {
    border-right: 1px solid transparent;
  }
  .catalog-list-item:nth-child(4n+4) {
    border-right: 1px solid #dddddd;
  }
  .catalog-list-item:nth-child(5) {
    border-top: 1px solid transparent;
  }
  .additional-features-list .list-item-title {
    font-size: 18px;
  }
}
@media (max-width: 780px) {
  .catalog-list-item {
    width: 33%;
  }
  .catalog-list-item:nth-child(5n+5) {
    border-right: 1px solid transparent;
  }
  .catalog-list-item:nth-child(4n+4) {
    border-right: 1px solid transparent;
  }
  .catalog-list-item:nth-child(3n+3) {
    border-right: 1px solid #dddddd;
  }
  .catalog-list-item:nth-child(4) {
    border-top: 1px solid transparent;
  }
  .catalog-list-item .menu-list {
    padding-right: 10px;
  }
  .catalog-list-item .menu-list-item {
    padding-left: 15px;
    font-size: inherit;
  }
  .additional-features-list .list-item {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 570px) {
  .catalog-list_m-t {
    margin-top: 15px;
  }
  .catalog-list-item {
    width: 100%;
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dddddd;
  }
  .catalog-list-item::after {
    display: none;
  }
  .catalog-list-item::before {
    transform: rotate(0);
    border: none;
    background-image: url(../img/icons/right-arrow-angle_green.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    right: 30px;
    left: auto;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .catalog-list-item:hover {
    box-shadow: none;
  }
  .catalog-list-item:hover .menu-list {
    opacity: 0;
    visibility: hidden;
    box-shadow: none;
  }
  .catalog-list-item:hover::before {
    transform: rotate(0);
    border: none;
    background-image: url(../img/icons/right-arrow-angle_green.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    right: 30px;
    left: auto;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .catalog-list-item .menu-list-item {
    padding-left: 70px;
    font-size: 14px;
  }
  .catalog-list-item .menu-list-item_close {
    display: flex;
  }
  .catalog-list-item.active .menu-list {
    opacity: 1;
    visibility: visible;
  }
  .catalog-list-item.active .menu-list::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    width: 100%;
    height: 5px;
    background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.1));
  }
  .catalog-list-item.active .menu-list-item_close {
    transform: rotate(-90deg);
    border: none;
    background-image: url(../img/icons/right-arrow-angle_green.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 15px;
    height: 15px;
    right: 0;
    left: 0;
    bottom: 20px;
    position: absolute;
    margin: auto;
  }
  .catalog-list-item.active::before {
    display: none;
  }
  .catalog-list-item:nth-child(3n+3),
  .catalog-list-item:nth-child(1),
  .catalog-list-item:nth-child(2) {
    border: none;
  }
  .catalog-list-item:nth-child(3n+3) {
    border-bottom: 1px solid #dddddd;
  }
  .catalog-list-item:nth-child(1),
  .catalog-list-item:nth-child(2),
  .catalog-list-item:nth-child(3) {
    border-top: 1px solid #dddddd;
  }
  .catalog-list-item:last-child {
    border-bottom: 1px solid #dddddd;
  }
  .catalog-list-item .title-block {
    width: 100%;
    justify-content: flex-start;
    padding-left: 28px;
  }
  .catalog-list-item .title-block-title {
    width: auto;
    margin-top: 0;
    width: 90%;
    text-align: left;
    padding-left: 15px;
    text-transform: none;
    font-size: 13px;
  }
  .catalog-list-item .img {
    width: 29px;
    height: 29px;
  }
  .additional-features-list .list-item {
    margin-bottom: 0;
  }
  .additional-features-list .list-item:nth-child(1),
  .additional-features-list .list-item:nth-child(2) {
    margin-bottom: 20px;
  }
  .additional-features-list .list-item .img {
    max-width: 74px;
    max-height: 74px;
  }
  .additional-features-list .list-item-title {
    font-size: 13px;
  }
}
@media (max-width: 360px) {
  .catalog-list-item .title-block {
    padding-left: 15px;
  }
  .catalog-list-item::before {
    right: 15px;
  }
  .catalog-list-item:hover::before {
    right: 15px;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.contact-wrap {
  overflow: hidden;
}
.address-block {
  padding-top: 90px;
  padding-right: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.address-block .title {
  margin-bottom: 15px;
}
.address-block {
  width: 49%;
}
.map-block {
  width: 51%;
}
@media (max-width: 780px) {
  .address-block {
    padding-right: 45px;
  }
}
@media (max-width: 570px) {
  .address-block {
    width: 100%;
    padding-right: 0;
    background-color: white;
    padding-top: 0;
  }
  .address-block .title {
    margin-bottom: 10px;
  }
  .address-block .work-time {
    order: 1;
    padding-left: 0;
    width: auto;
  }
  .map-block {
    width: 100%;
  }
  .address-block-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .address-block-content .p.bold {
    font-weight: 400;
  }
  .address-block-content .work-title {
    display: none;
  }
  .address-block-content .contacts {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 25px;
  }
  .address-block-content .contacts-mail {
    margin-left: 35px;
  }
  .address-block-content .phone-icon::before {
    width: 13px;
    height: 13px;
  }
  .address-block-content .mail-icon::before {
    width: 14px;
    height: 14px;
  }
  .address-block-content .contacts-phone.phone-icon::before {
    left: -20px;
    top: 1px;
  }
  .address-block-content .contacts-mail.mail-icon::before {
    left: -21px;
  }
  .map-block {
    height: 270px;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.call-back-form-wrap {
  padding-top: 40px;
  padding-bottom: 40px;
}
.input,
.textarea {
  border: 1px solid #dddddd;
  padding: 23px;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  resize: none;
}
.textarea_h-130 {
  height: 130px;
}
.form_col-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form_col-3 .form-item {
  width: 32.5%;
  margin-bottom: 15px;
}
.form_col-3 .form-item:last-child {
  margin-bottom: 0;
}
.form-item.form-item_full {
  width: 100%;
}
.form-item {
  position: relative;
}
.form-alert {
  position: absolute;
  left: 0;
  top: -20px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #c52121;
  background-color: white;
}
.form-alert::after {
  content: '';
  display: block;
  width: 5px;
  height: 5px;
  border-left: 1px solid #c52121;
  border-bottom: 1px solid #c52121;
  background-color: white;
  transform: rotate(-45deg);
  position: absolute;
  top: 100%;
  left: 15px;
  margin-top: -2px;
  z-index: 1;
}
@media (max-width: 570px) {
  .call-back-form-wrap {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .form_col-3 .form-item {
    width: 100%;
  }
  .input,
  .textarea {
    padding: 10px;
  }
}

.header,
.footer {
  width: 100%;
}
.header .contacts,
.footer .contacts {
  padding-left: 30px;
}
.footer_padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.main-logo_size {
  width: 152px;
}
.main-logo .img {
  width: 143px;
}
.main-logo .desc {
  display: block;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
}
.work-time {
  width: 134px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 300;
}
.work-time .desc-title {
  width: 45px;
  display: inline-block;
}
.work-time_font-normal {
  font-weight: 400;
}
.work-time_font-normal .desc-title {
  width: 45px;
}
.hdr-nav-wrap {
  display: flex;
}
.hdr-nav-item {
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-left: 35px;
  padding-right: 35px;
  text-decoration: none;
  color: #292929;
  transition: 0.2s;
  font-weight: 600;
  cursor: pointer;
}
.hdr-nav-item:hover,
.hdr-nav-item.active {
  color: #005f4c;
}
.hdr-nav-item::after {
  content: '';
  display: block;
  width: 1px;
  height: 20px;
  background-color: #bababa;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.hdr-nav-item:last-child::after {
  display: none;
}
.contacts-address {
  margin-bottom: 10px;
}
.contacts-address .span {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 300;
}
.contacts-address.baloone-icon {
  position: relative;
}
.contacts-address.baloone-icon::before {
  position: absolute;
  left: -36px;
  top: 6px;
}
.contacts-phone {
  position: relative;
}
.contacts-phone_m-b {
  margin-bottom: 15px;
}
.contacts-phone.phone-icon::before {
  position: absolute;
  left: -30px;
  top: 4px;
}
.phone-number,
.mail-item {
  display: block;
  font-weight: 600;
  line-height: 1.2;
  color: #292929;
  text-decoration: none;
  transition: 0.2s;
}
.phone-number:hover,
.mail-item:hover {
  color: #005f4c;
}
.contacts-mail.mail-icon {
  position: relative;
}
.contacts-mail.mail-icon::before {
  position: absolute;
  left: -30px;
  top: 1px;
}
.contacts_p-l {
  padding-left: 32px;
}
.under-footer_padding {
  padding-top: 40px;
  padding-bottom: 40px;
}
.under-footer .span {
  font-size: 16px;
  color: #7d7d7d;
}
@media (max-width: 1200px) {
  .hdr-nav-item {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1000px) {
  .main-logo {
    order: 1;
  }
  .work-time {
    order: 2;
  }
  .contacts {
    order: 3;
  }
  .hdr-nav-wrap {
    width: 100%;
    order: 4;
    margin-top: 10px;
    background-color: #005f4c;
  }
  .hdr-nav-item {
    width: 25%;
    color: white;
  }
  .hdr-nav-item:hover {
    color: white;
    background-color: #00493a;
  }
  .contacts-phone_m-b {
    margin-bottom: 5px;
  }
  .contacts-address .span {
    font-size: 14px;
  }
  .footer-wrap .hdr-nav-wrap {
    order: 0;
    margin-top: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 780px) {
  .hdr-nav-item {
    font-size: 15px;
  }
  .under-footer_padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 570px) {
  .main-logo {
    width: 50%;
    padding-left: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-logo .img {
    width: 100px;
  }
  .main-logo .desc {
    font-size: 9px;
  }
  .work-time {
    width: 50%;
    order: 3;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 30px;
    margin-top: 20px;
  }
  .contacts {
    width: 50%;
    order: 2;
    padding-right: 28px;
  }
  .mail-item,
  .phone-number {
    font-size: 12px;
  }
  .header {
    position: relative;
  }
  .header .contacts-mail.mail-icon::before {
    width: 14px;
    height: 14px;
    left: -21px;
  }
  .header .phone-icon::before {
    width: 14px;
    height: 14px;
    left: -22px;
  }
  .header.d-flex_j-c-s-b {
    justify-content: flex-end;
  }
  .contacts-address {
    left: 57px;
    top: 100px;
  }
  .contacts-address .span {
    font-size: 12px;
  }
  .contacts .baloone-icon::before {
    width: 19px;
    height: 19px;
    left: -22px;
    top: 3px;
  }
  .contacts-address.baloone-icon {
    position: absolute;
  }
  .work-time .desc-title {
    width: 38px;
  }
  .hdr-nav-wrap {
    flex-wrap: wrap;
  }
  .hdr-nav-item {
    width: 100%;
    height: 40px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
    border-bottom: 1px solid #bababa;
  }
  .hdr-nav-item:last-child {
    border-bottom: none;
  }
  .hdr-nav-item::after {
    display: none;
  }
  .footer {
    position: relative;
  }
  .footer .main-logo {
    display: none;
  }
  .footer .contacts-address {
    top: auto;
    bottom: 99px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  .footer .contacts {
    padding-left: 0;
    padding-right: 30;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .footer .contacts .baloone-icon::before {
    position: relative;
    left: auto;
    top: auto;
  }
  .footer .work-time {
    padding-left: 0;
    order: 1;
    transform: translateY(10px);
    padding-left: 30px;
  }
  .footer .hdr-nav-item {
    color: #292929;
  }
  .footer .hdr-nav-wrap {
    margin-bottom: 30px;
    background-color: #c6c6c6;
  }
  .footer .mail-item,
  .footer .phone-number {
    color: white;
  }
  .footer .baloone-icon::before {
    background-image: url(../img/icons/place-localizer_white.svg);
    margin-bottom: -3px;
    margin-right: 10px;
  }
  .footer .phone-icon::before {
    background-image: url(../img/icons/telephone-handle-silhouette_white.svg);
    width: 15px;
    height: 15px;
  }
  .footer .mail-icon::before {
    background-image: url(../img/icons/envelope_white.svg);
    width: 15px;
    height: 15px;
    left: -22px;
  }
  .footer .work-time {
    margin-top: 15px;
  }
  .footer-wrap.gray-container {
    background-color: #555555;
    color: white;
  }
  .footer_padding {
    padding-top: 0;
  }
}
@media (max-width: 360px) {
  .main-logo {
    padding-left: 0px;
  }
  .footer .contacts,
  .header .contacts {
    padding-right: 15px;
    padding-left: 36px;
  }
  .work-time {
    padding-left: 38px;
  }
  .footer .work-time {
    padding-left: 15px;
  }
  .contacts-address {
    left: 46px;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.body {
  font-family: 'Open Sans', sans-serif;
  cursor: default;
}
.container {
  width: 1170px;
  margin: 0 auto;
}
.container_full {
  width: 100%;
}
.container_padding {
  padding-left: 15px;
  padding-right: 15px;
}
.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.d-flex_a-i-end {
  align-items: flex-end;
}
.d-flex_j-c-s-b {
  justify-content: space-between;
}
.d-flex_center {
  justify-content: center;
}
.m-t_65 {
  margin-top: 65px;
}
.m-t_20 {
  margin-top: 20px;
}
.m-t_25 {
  margin-top: 25px;
}
.m-t_95 {
  margin-top: 95px;
}
.m-t_130 {
  margin-top: 130px;
}
.m-t_170 {
  margin-top: 170px;
}
.m-t_35 {
  margin-top: 35px;
}
.m-t_40 {
  margin-top: 40px;
}
.m-t_50 {
  margin-top: 50px;
}
.col-3 {
  width: 33%;
}
.baloone-icon::before {
  content: '';
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(../img/icons/place-localizer_green.svg);
  background-size: 95%;
  background-position: center;
  background-repeat: no-repeat;
}
.phone-icon::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(../img/icons/telephone-handle-silhouette_green.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.phone-icon_orange::before {
  background-image: url(../img/icons/telephone-handle-silhouette_orange.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.mail-icon::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url(../img/icons/envelope_green.svg);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.mail-icon_orange::before {
  background-image: url(../img/icons/envelope_orange.svg);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.gray-container {
  background-color: #dddddd;
}
.light-gray-container {
  background-color: #f4f4f4;
}
.dark-back {
  background-color: #0a0a0a;
}
.close-icon::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../img/icons/close.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1200px) {
  .container {
    width: 991px;
  }
}
@media (max-width: 1000px) {
  .container {
    width: 768px;
  }
  .m-t_95 {
    margin-top: 60px;
  }
  .m-t_170 {
    margin-top: 90px;
  }
}
@media (max-width: 780px) {
  .container {
    width: 560px;
  }
  .m-t_170 {
    margin-top: 60px;
  }
}
@media (max-width: 570px) {
  .container {
    width: 100%;
    padding-left: 28px;
    padding-right: 28px;
  }
  .container_no-p {
    padding-left: 0;
    padding-right: 0;
  }
  .m-t_25 {
    margin-top: 0;
  }
  .m-t_95 {
    margin-top: 35px;
  }
  .m-t_65 {
    margin-top: 30px;
  }
  .m-t_40 {
    margin-top: 12px;
  }
  .m-t_130 {
    margin-top: 40px;
  }
  .m-t_170 {
    margin-top: 40px;
  }
  .m-t_35 {
    margin-top: 20px;
  }
  .m-t_50 {
    margin-top: 15px;
  }
}
@media (max-width: 360px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .container_no-p {
    padding-left: 0;
    padding-right: 0;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.fade-in-extention {
  animation-name: fadeInExtention;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
.fade-out-extention {
  animation-name: fadeOutExtention;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
@keyframes fadeInExtention {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeOutExtention {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.5);
  }
}
.modal-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}
.modal {
  background-color: white;
  padding: 40px;
  position: relative;
}
.modal_size-460 {
  max-width: 460px;
}
.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  opacity: 1;
  transition: 0.2s;
}
.close-modal:hover {
  opacity: 0.5;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKWyV-hs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem6YaGs126MiZpBA-UFUJ0e.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/memnYaGs126MiZpBA-UFUKXGUehs.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UN_r8-VQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-U1Ug.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v20/mem5YaGs126MiZpBA-UNirk-VQ.ttf) format('truetype');
}
.h1 {
  font-size: 30px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 600;
}
.h1_center {
  text-align: center;
}
.h2 {
  font-size: 30px;
  line-height: 1.5;
  text-transform: uppercase;
  font-weight: 600;
}
.h2_center {
  text-align: center;
}
.h2_orange {
  color: #e97402;
}
.h2_32 {
  font-size: 32px;
}
.bold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.orange-txt {
  color: #e97402;
}
.p {
  font-size: 1rem;
  line-height: 1.3;
}
@media (max-width: 570px) {
  .h1 {
    font-size: 18px;
  }
  .h2 {
    font-size: 18px;
  }
}

