@import './variable.less';
@import './fonts.less';
@import './animation.less';

.modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0, .3);
}

.modal {
    background-color: @white;
    padding: 40px;
    position: relative;

    &_size-460 {
        max-width: 460px;
    }
}

.close-modal {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    opacity: 1;    
    transition: .2s;

    &:hover {
        opacity: 0.5;
    }
}