@import './variable.less';
@import './fonts.less';

.btn {
    text-transform: uppercase;
    border: 1px solid @white;
    color: @white;
    font-weight: 600;
    background-color: @green;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2;
    cursor: pointer;
    transition: .2s;

    &:hover {
        background-color: @green/1.5;
    }

    &_large {
        font-size: 24px;
        padding: 20px 80px;
    }
}

@media (max-width: 1000px) {
    .btn_large {
        font-size: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@media (max-width: 570px) {
    .btn_large {
        font-size: 16px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
    }
}