@import './fonts.less';
@import './variable.less';

.body {
    font-family: 'Open Sans', sans-serif;
    cursor: default;
}

.container {
    width: 1170px;
    margin: 0 auto;

    &_full {
        width: 100%;
    }

    &_padding {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.d-flex {
    display: flex;
    flex-wrap: wrap;

    &_a-i-end {
        align-items: flex-end;
    }

    &_j-c-s-b {
        justify-content: space-between;
    }

    &_center {
        justify-content: center;
    }
}

.m-t_65 {
    margin-top: 65px;
}

.m-t_20 {
    margin-top: 20px;
}

.m-t_25 {
    margin-top: 25px;
}

.m-t_95 {
    margin-top: 95px;
}

.m-t_130 {
    margin-top: 130px;
}

.m-t_170 {
    margin-top: 170px;
}

.m-t_35 {
    margin-top: 35px;
}

.m-t_40 {
    margin-top: 40px;
}

.m-t_50 {
    margin-top: 50px;
}

.col-3 {
    width: 33%;
}

.baloone-icon {
    &::before {
        content: '';
        display: inline-block;
        width: 28px;
        height: 28px;
        background-image: url(../img/icons/place-localizer_green.svg);
        background-size: 95%;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.phone-icon {
    &::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url(../img/icons/telephone-handle-silhouette_green.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.phone-icon_orange {
    &::before {
        background-image: url(../img/icons/telephone-handle-silhouette_orange.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.mail-icon {
    &::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url(../img/icons/envelope_green.svg);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
}

.mail-icon_orange {
    &::before {
        background-image: url(../img/icons/envelope_orange.svg);
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
    }
}

.gray-container {
    background-color: @borderGray;
}

.light-gray-container {
    background-color: @lightGray;
}

.dark-back {
    background-color: @blackBack;
}

.close-icon {
    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: url(../img/icons/close.svg);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media (max-width: 1200px) {
    .container {
        width: 991px;
    }
}


@media (max-width: 1000px) {
    .container {
        width: 768px;
    }

    .m-t_95 {
        margin-top: 60px;
    }

    .m-t_170 {
        margin-top: 90px;
    }
}

@media (max-width: 780px) {
    .container {
        width: 560px;
    }

    .m-t_170 {
        margin-top: 60px;
    }
}

@media (max-width: 570px) {
    .container {
        width: 100%;
        padding-left: 28px;
        padding-right: 28px;

        &_no-p {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .m-t_25 {
        margin-top: 0;
    }

    .m-t_95 {
        margin-top: 35px;
    }

    .m-t_65 {
        margin-top: 30px;
    }

    .m-t_40 {
        margin-top: 12px;
    }

    .m-t_130 {
        margin-top: 40px;
    }

    .m-t_170 {
        margin-top: 40px;
    }

    .m-t_35 {
        margin-top: 20px;
    }

    .m-t_50 {
        margin-top: 15px;
    }
}

@media (max-width: 360px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;

        &_no-p {
            padding-left: 0;
            padding-right: 0;
        }
    }
}