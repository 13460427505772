
.fade-in-extention {
    animation-name: fadeInExtention;
    animation-duration: .4s;
    animation-fill-mode: forwards;
}

.fade-out-extention {
    animation-name: fadeOutExtention;
    animation-duration: .4s;
    animation-fill-mode: forwards;
}

@keyframes fadeInExtention {
    from {
        opacity: 0;
        transform: scale(.5);
    } to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fadeOutExtention {
    from {
        opacity: 1;
        transform: scale(1);
    } to {
        opacity: 0;
        transform: scale(.5);
    }
}