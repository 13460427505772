@import './variable.less';
@import './fonts.less';

.general-advantages-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 329px;

    .img-wrap {
        width: 105px;
        height: 105px;
        background-color: @orange;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-bottom: 20px;
    }

    .img {
        width: 78px;
        height: 60px;
        max-width: 90%;
        max-height: 90%;
    }

    .advantage-title {
        font-size: 20px;
        line-height: 1.3;
    }
}

@media (max-width: 1000px) {
    .general-advantages-item {
        width: 33%;

        .advantage-title {
            font-size: 18px;
        }
    }
}

@media (max-width: 780px) {
    .general-advantages-item {
        .advantage-title {
            font-size: 15px;
        }
    }
}

@media (max-width: 570px) {
    .general-advantages-item {
        width: 100%;
        flex-direction: row;
        margin-bottom: 20px;

        .img-wrap {
            width: 75px;
            height: 75px;
            margin-bottom: 0;
        }

        .img {
            width: 60%;
            height: auto;
        }

        .advantage-title {
            text-align: left;
            padding-left: 15px;
            width: 75%;
        }
    }
}