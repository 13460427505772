@import './variable.less';

.header,
.footer {
    width: 100%;

    .contacts {
        padding-left: 30px;
    }
}

.footer_padding {
    padding-top: 30px;
    padding-bottom: 30px;
}

.main-logo {

    &_size {
        width: 152px;
    }

    .img {
        width: 143px;
    }

    .desc {
        display: block;
        font-size: 12px;
        text-align: center;
        font-weight: 500;
        line-height: 1.2;
    }
}

.work-time {
    width: 134px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;

    .desc-title {
        width: 45px;
        display: inline-block;
    }

    &_font-normal {
        font-weight: 400;

        .desc-title {
            width: 45px;
        }
    }
}

.hdr-nav-wrap {
    display: flex;
}

.hdr-nav-item {
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
    text-decoration: none;
    color: @black;
    transition: 0.2s;
    font-weight: 600;
    cursor: pointer;

    &:hover,
    &.active {
        color: @green;
    }

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 20px;
        background-color: @grayColor; 
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.contacts-address {
    margin-bottom: 10px;
    .span {
        display: block;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 300;
    }

    &.baloone-icon {
        position: relative;
        &::before {
            position: absolute;
            left: -36px;
            top: 6px;
        }
    }
}

.contacts-phone {
    position: relative; 
    &_m-b {
        margin-bottom: 15px;
    }

    &.phone-icon {
        &::before {
            position: absolute;
            left: -30px;
            top: 4px;
        }
    }
}

.phone-number,
.mail-item {
    display: block;
    font-weight: 600;
    line-height: 1.2;
    color: @black;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
        color: @green;
    }
}

.contacts-mail {
    &.mail-icon {
        position: relative;
        &::before {
            position: absolute;
            left: -30px;
            top: 1px;
        }
    }
}

.contacts_p-l {
    padding-left: 32px;
}

.under-footer {
    &_padding {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .span {
        font-size: 16px;
        color: #7d7d7d;
    }
}

@media (max-width: 1200px) {
    .hdr-nav-item {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 1000px) {
    .main-logo {
        order: 1;
    }

    .work-time {
        order: 2;
    }

    .contacts {
        order: 3;
    }

    .hdr-nav-wrap {
        width: 100%;
        order: 4;
        margin-top: 10px;
        background-color: @green;
    }

    .hdr-nav-item {
        width: 25%;
        color: @white;

        &:hover {
            color: @white;
            background-color: @green/1.3;
        }
    }

    .contacts-phone_m-b {
        margin-bottom: 5px;
    }

    .contacts-address {
        .span {
            font-size: 14px;
        }
    }

    .footer-wrap {
        .hdr-nav-wrap {
            order: 0;
            margin-top: 0;
            margin-bottom: 15px;
        }
    }
}

@media (max-width: 780px) {

    .hdr-nav-item {
        font-size: 15px;
    }

    .under-footer_padding {
        padding-top: 20px;
        padding-bottom: 20px;
    }

}

@media (max-width: 570px) {
    .main-logo {
        width: 50%;
        padding-left: 28px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
            width: 100px;
        }

        .desc {
            font-size: 9px;
        }
    }

    .work-time {
        width: 50%;
        order: 3;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 30px;
        margin-top: 20px;
    }

    .contacts {
        width: 50%;
        order: 2;
        padding-right: 28px;
    }

    .mail-item, 
    .phone-number {
        font-size: 12px;
    }

    .header {
        position: relative;

        .contacts-mail.mail-icon::before {
            width: 14px;
            height: 14px;
            left: -21px;
        }

        .phone-icon::before {
            width: 14px;
            height: 14px;
            left: -22px;
        }
    }

    .header.d-flex_j-c-s-b {
        justify-content: flex-end;
    }

    .contacts-address {
        left: 57px;
        top: 100px;
        .span {
            font-size: 12px;
        }
    }

    .contacts {
        .baloone-icon::before  {
            width: 19px;
            height: 19px;
            left: -22px;
            top: 3px;
        }
    }
    

    .contacts-address.baloone-icon {
        position: absolute;
    }

    .work-time .desc-title {
        width: 38px;
    }

    .hdr-nav-wrap {
        flex-wrap: wrap;
    }

    .hdr-nav-item {
        width: 100%;
        height: 40px;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 13px;
        border-bottom: 1px solid @grayColor;

        &:last-child {
            border-bottom: none;
        }

        &::after {
            display: none;
        }
    }

    .footer {
        position: relative;

        .main-logo {
            display: none;
        }

        .contacts-address {
            top: auto;
            bottom: 99px;
            width: 100%;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
        }

        .contacts {
            padding-left: 0;
            padding-right: 30;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .baloone-icon::before {
                position: relative;
                left: auto;
                top: auto;
            }
        }

        .work-time {
            padding-left: 0;
            order: 1;
            transform: translateY(10px);
            padding-left: 30px;
        }

        .hdr-nav-item {
            color: @black;
        }

        .hdr-nav-wrap {
            margin-bottom: 30px;
            background-color: #c6c6c6;
        }

        .mail-item, .phone-number {
            color: @white;
        }

        .baloone-icon::before {
            background-image: url(../img/icons/place-localizer_white.svg);
            margin-bottom: -3px;
            margin-right: 10px;
        }

        .phone-icon::before {
            background-image: url(../img/icons/telephone-handle-silhouette_white.svg);
            width: 15px;
            height: 15px;
        }

        .mail-icon::before {
            background-image: url(../img/icons/envelope_white.svg);
            width: 15px;
            height: 15px;
            left: -22px;
        }

        .work-time {
            margin-top: 15px;
        }
    }

    .footer-wrap.gray-container {
        background-color: #555555;
        color: @white;
    }

    .footer_padding {
        padding-top: 0;
    }
}

@media (max-width: 360px) {
    .main-logo {
        padding-left: 0px;
    }

    .footer .contacts, .header .contacts {
        padding-right: 15px;
        padding-left: 36px;
    }

    .work-time {
        padding-left: 38px;
    }

    .footer {
        .work-time {
            padding-left: 15px;
        }
    }

    .contacts-address {
        left: 46px;
    }

}