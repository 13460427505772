@import './variable.less';
@import './fonts.less';

.catalog-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &_m-t {
        margin-top: 40px;
    }
}

.catalog-list-item {
    width: 20%; 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border: 1px solid @borderGray;
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;

    &:hover {
        box-shadow: 0px 0px 10px rgba(0,0,0, .2);
        z-index: 2;
        background-color: @white;

        &::before {
            z-index: 5;
            background-color: @white;
            transform: rotate(0deg);
            width: 100%;
            height: 15px;
            margin-top: -19px;
            border: none;
        }

        .menu-list {
            box-shadow: 0px 0px 10px rgba(0,0,0, .2);
            opacity: 1;
            visibility: visible;
        }

        .img {
            display: none;
        }

        .img_hover {
            display: block;
        }
    }

    &::after,
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: -7px;
        background-color: @white;
        width: 15px;
        height: 15px;
        border-left: 1px solid @borderGray;
        border-bottom: 1px solid @borderGray;
        transform: rotate(-45deg);
    }

    &::before {
        width: 8px;
        height: 8px;
        margin-top: -8px;
        z-index: 1;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
        border-top: 1px solid @borderGray;
    }

    &:nth-child(5n+5),
    &:last-child {
        border-right: 1px solid @borderGray;
    }
    
    &_no-sub {
        &::after,
        &::before {
            display: none;
        }
    }

    .img {
        max-height: 67px;
        max-width: 65px;
    }

    .img_hover {
        display: none;
    }

    .menu-list {
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        position: absolute;
        background-color: @white;
        z-index: 2;
        margin-top: -5px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        border-bottom: 1px solid @borderGray;
    }

    .menu-list-item {
        line-height: 1.3;
        padding-left: 40px;
        position: relative;
        color: @black;
        text-decoration: none;
        padding-bottom: 10px;
        transition: 0.2s;
        cursor: default;

        &:last-child {
            padding-bottom: 0;
        }

        &::before {
            transition: 0.2s;
        }

        // &:hover {
        //     color: @orange;
        //     &::before {
        //         content: '';
        //         display: block;
        //         width: 9px;
        //         height: 9px;
        //         background-color: @orange;
        //         position: absolute;
        //         left: 0;
        //         top: 6px;
        //     }
        // }
    }

    .menu-list-item_close {
        display: none;
    }

    .title-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: @black;
        pointer-events: none;
    }

    .title-block-title {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 13px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 1.3;
        transition: .2s;

        &:hover {
            color: @green;
        }
    }
}

.additional-features-list {
    display: flex;
    flex-wrap: wrap;

    .list-item {
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
            max-width: 100px;
            max-height: 93px;
            margin-bottom: 20px;
        }
    }

    .list-item-title {
        font-size: 24px;
        line-height: 1.3;
        text-align: center;
    }
}

@media (max-width: 1000px) {
    .catalog-list-item {
        width: 25%;

        &:nth-child(5n+5) {
            border-right: 1px solid transparent;
        }
        &:nth-child(4n+4) {
            border-right: 1px solid @borderGray;
            
        }

        &:nth-child(5) {
            border-top: 1px solid transparent;
        }
    }

    .additional-features-list {
        .list-item-title {
            font-size: 18px;
        }
    }
}

@media (max-width: 780px) {
    .catalog-list-item {
        width: 33%;

        &:nth-child(5n+5) {
            border-right: 1px solid transparent;
        }
        &:nth-child(4n+4) {
            border-right: 1px solid transparent;
            
        }
        &:nth-child(3n+3) {
            border-right: 1px solid @borderGray;
            
        }

        &:nth-child(4) {
            border-top: 1px solid transparent;
        }

        .menu-list {
            padding-right: 10px;
        }

        .menu-list-item {
            padding-left: 15px;
            font-size: inherit;
        }
    }

    .additional-features-list {
        .list-item {
            width: 50%;
            margin-bottom: 30px;
        }
    }
}

@media (max-width: 570px) {
    .catalog-list_m-t {
        margin-top: 15px;
    }

    .catalog-list-item {
        width: 100%;
        border: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid @borderGray;

        &::after {
            display: none;
        }

        &::before {
            transform: rotate(0);
            border: none;
            background-image: url(../img/icons/right-arrow-angle_green.svg);
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            right: 30px;
            left: auto;
            top: 0;
            bottom: 0;
            margin: auto;
        }

        &:hover {
            box-shadow: none;
            .menu-list {
                opacity: 0;
                visibility: hidden;
                box-shadow: none;
            }

            &::before {
                transform: rotate(0);
                border: none;
                background-image: url(../img/icons/right-arrow-angle_green.svg);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
                right: 30px;
                left: auto;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .menu-list-item {
            padding-left: 70px;
            font-size: 14px;
        }

        .menu-list-item_close {
            display: flex;
        }

        &.active {
            .menu-list {
                opacity: 1;
                visibility: visible;
                
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -5px;
                    width: 100%;
                    height: 5px;
                    background: linear-gradient(to top, transparent, rgba(0,0,0, .1));
                }
            }

            .menu-list-item_close {
                transform: rotate(-90deg);
                border: none;
                background-image: url(../img/icons/right-arrow-angle_green.svg);
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
                right: 0;
                left: 0;
                bottom: 20px;
                position: absolute;
                margin: auto;
            }

            &::before {
                display: none;
            }
        }

        &:nth-child(3n+3),
        &:nth-child(1),
        &:nth-child(2) {
            border: none;
        }

        &:nth-child(3n+3) {
            border-bottom: 1px solid @borderGray;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            border-top: 1px solid @borderGray;
        }
        &:last-child {
            border-bottom: 1px solid @borderGray;
        }

        .title-block {
            width: 100%;
            justify-content: flex-start;
            padding-left: 28px;
        }

        .title-block-title {
            width: auto;
            margin-top: 0;
            width: 90%;
            text-align: left;
            padding-left: 15px;
            text-transform: none;
            font-size: 13px;
        }

        .img {
            width: 29px;
            height: 29px;
        }
    }

    .additional-features-list {
        .list-item {
            margin-bottom: 0;
            
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 20px;
            }


            .img {
                max-width: 74px;
                max-height: 74px;
            }
        }

        .list-item-title {
            font-size: 13px;
        }
    }
}

@media (max-width: 360px) {
    .catalog-list-item {
        .title-block {
            padding-left: 15px;
        }

        &::before {
            right: 15px;
        }

        &:hover {
            &::before {
                right: 15px;
            }
        }
    }
}