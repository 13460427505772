@import './variable.less';
@import './fonts.less';

.contact-wrap {
    overflow: hidden;
}

.address-block {
    padding-top: 90px;
    padding-right: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .title {
        margin-bottom: 15px;
    }
}

.address-block {
    width: 49%;
}

.map-block {
    width: 51%;
}

@media (max-width: 780px) {
    .address-block {
        padding-right: 45px;
    }
}

@media (max-width: 570px) {
    .address-block {
        width: 100%;
        padding-right: 0;
        background-color: @white;
        padding-top: 0;

        .title {
            margin-bottom: 10px;
        }

        .work-time {
            order: 1;
            padding-left: 0;
            width: auto;
        }
    }

    .map-block {
        width: 100%;
    }

    .address-block-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .p.bold {
            font-weight: 400;
        }

        .work-title {
            display: none;
        }

        .contacts {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 25px;
        }

        .contacts-mail {
            margin-left: 35px;
        }

        .phone-icon::before {
            width: 13px;
            height: 13px;
        }

        .mail-icon::before {
            width: 14px;
            height: 14px;
        }

        .contacts-phone.phone-icon::before {
            left: -20px;
            top: 1px;   
        }

        .contacts-mail.mail-icon::before {
            left: -21px;
        }
    }

    .map-block {
        height: 270px;
    }
}